.carousel-container {
    overflow: hidden;
    width: 70%;
    position: relative;
    margin: auto;
}

.carousel {
    display: flex;
    animation: scroll 30s linear infinite;
}

.col {
    box-sizing: border-box;
    min-width: 20%;  /* Ensure this matches the number of visible images */
}

.img-fluid {
    width: 100%;
    height: auto;
}

/* Mobile: show 3 images */
@media (max-width: 768px) {
    .col {
        min-width: 33.33%;
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
